<template>
	<div class="printMain body-bg" v-loading="loading_load">
		<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
		<div style="">
			<div class="printer_con">
				<div class="page_table" v-for="(itemP,indexP) in priterData" :key="indexP">
					<table class="simTable printerTable">
						<tr>
							<th width="50%">Topline Bill of Lading<span style="padding-left: 10mm;">Not Negotiable</span></th>
							<td width="50%"><strong>No.</strong><span style="padding-left: 5mm;">{{itemP.planNo}}</span></td>
						</tr>
						<tr>
							<th class="prt_tit_1">
								Ship From
							</th>
							<td></td>
						</tr>
						<tr>
							<td>
								<div v-if="itemP.shipFrom">
									<div>
										<strong>Name:&nbsp;</strong>{{itemP.shipFrom.company}}
									</div>
									<div>
										<strong>Address:&nbsp;</strong>{{itemP.shipFrom.address}},
										{{itemP.shipFrom.city}},
										{{itemP.shipFrom.state}}&nbsp;
										{{itemP.shipFrom.postalCode}}&nbsp;
										{{itemP.shipFrom.country}}
									</div>
									<div>
										<strong>Contact:&nbsp;</strong>{{itemP.shipFrom.phoneNumber}}
									</div>
								</div>
							</td>
							<td>
								<div>
									<strong>Location:&nbsp;</strong>{{itemP.location}}
								</div>
							</td>
						</tr>
						<tr>
							<th class="prt_tit_1">Ship To</th>
							<th class="prt_tit_1">References</th>
						</tr>
						<tr>
							<td>
								<!-- 17007 Evergreen Pl, City of Industry, CA 91745美国 -->
								<div v-if="itemP.shipTo">
									<div>
										<strong>Name:&nbsp;</strong>{{itemP.shipTo.name}}
									</div>
									<div>
										<strong>Address:&nbsp;</strong>{{itemP.shipTo.address}},
										{{itemP.shipTo.city}},
										{{itemP.shipTo.state}}&nbsp;
										{{itemP.shipTo.zipCode}}&nbsp;
										{{itemP.shipTo.country}}
									</div>
									<div>
										<strong>Contact:&nbsp;</strong>{{itemP.shipTo.phone}}
									</div>
								</div>
							</td>
							<td style="text-align: center;">
								<span class="prt_tit_0">
									{{itemP.references}}
								</span>
							</td>
						</tr>
						<tr>
							<th class="prt_tit_1">Prepaid, 3rd Party Bill To</th>
							<td>
							</td>
						</tr>
						<tr>
							<th colspan="2">Accessorials:</th>
						</tr>

						</tbody>
					</table>
					<div style="min-height: 80mm; border: 1px solid #999999;">
						<table class="simTable printerTable prt_tit_3">
							<tbody>
								<tr>
									<th>Mark</th>
									<!-- <th>Description</th> -->
									<!-- <th>Rel No.</th> -->
									<th>FBA</th>
									<th>PO</th>
									<th>Qty</th>

									<!-- <th>Weight<br />Volume</th> -->


								</tr>
								<tr v-for="(item,index) in itemP.records" :key="index">
									<td>{{item.CTNMark}}</td>
									<td>
										{{item.FBANo}}
									</td>
									<!-- <td>{{item.relationNo}}</td> -->
									<td>{{item.PO}}</td>
									<td>{{item.QTY}}</td>

									<!-- <td>{{item.totalWeight}}<br />{{item.totalVol}}</td> -->


								</tr>

							</tbody>
						</table>
					</div>
					<table class="simTable printerTable prt_tit_2">
						<tbody>
							<tr>
								<!-- <th colspan="3" class="prt_tit_2" style="text-align: right;">GRAND TOTALS: </th>
								<td colspan="2"></td> -->
								<td class=""><strong>Pallte:</strong>&nbsp;{{itemP.totalPallte}}</td>
								<td class=""><strong>QTY:</strong>&nbsp;{{itemP.QTY}}</td>
								<td class=""><strong>VOL:</strong>&nbsp;{{itemP.allTotalVol}}&nbsp;<strong>m³</strong></td>
								<td class=""><strong>Weight:</strong>&nbsp;{{itemP.allTotalWeight}}&nbsp;<strong>LB</strong></td>
								<!-- <td class=""><strong>GRAND TOTALS:</strong> </td> -->
								<!-- <td class=""></td> -->
							</tr>
						</tbody>
					</table>
					<table class="simTable printerTable">
						<tbody>
							<tr>
								<td class="prt_exp_2" colspan="3">
									<strong>Note: Liability limitation for loss or damage in this shipment may be applicable. See 49 USC
										14706(c)(1)(A) and (B).</strong>
								</td>
							</tr>
							<tr>
								<td width="60%">
									<div class="prt_tit_2">
										<strong>
											For Freight Collect Shipments:
										</strong>

									</div>
									<div class="prt_exp_1">
										If this shipment is to be delivered to the consignee, without recourse on the consignor, the
										consignor
										shall sign following statement: The carrier may decline to make delivery of this shipment without
										payment of freight and all other lawful charges.
									</div>
									<div>
										<span class="prt_tit_3">Signature of Consignor:</span> _______________________
									</div>
								</td>
								<td width="20%" class="prt_tit_2">
									<div style="padding-bottom: 5mm;">
										<strong>
											Trailer Loaded:
										</strong>
									</div>
									<div>

										____ <strong>By Shipper
										</strong>
									</div>
									<div>

										____ <strong>By Driver
										</strong>
									</div>
								</td>
								<td width="20%" class="prt_tit_2">
									<div style="padding-bottom: 5mm;">
										<strong>
											Freight Counted:
										</strong>
									</div>
									<div>

										____ <strong>By Shipper
										</strong>
									</div>
									<div>

										____ <strong>By Driver
										</strong>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="simTable printerTable">
						<tbody>
							<tr>
								<td width="50%">
									<div class="prt_tit_3"><strong>Shipper Signature/Date</strong></div>
									<div class="prt_exp_1">
										This is to certify that above named materials are properly classified, packaged, marked and labeled,
										and
										are in proper condition for transportation according to the applicable regulations of the DOT.
									</div>
									<div style="padding-top: 3mm;">
										<span class="prt_tit_3">Shipper:</span>______________<span class="prt_tit_3">Date:</span> _________
									</div>
								</td>
								<td width="50%">
									<div class="prt_tit_3"><strong>Carrier Signature/Pickup Date:</strong></div>
									<div class="prt_exp_1">
										Carrier acknowledges receipt of packages and required placards. Carrier certifies emergency response
										information was made available and/or carrier has the DOT emergency response guidebook or equivalent
										documentation in the vehicle. Property described above is received in good order, except as noted.
									</div>
									<div>
										<span class="prt_tit_3">Carrier:</span>______________ <span class="prt_tit_3">Date:</span>
										__________
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					
					<!-- 增值服务 -->
					
					<div style="height: 10px;" class="no_print"></div>
					<div v-show="isShowValueAdded" v-if="itemP.workOrderDtos && itemP.workOrderDtos.length>0">
						<div class="printer_break"></div>
						<whPrinterValueAddListSub :openTime="ValueAddOpenTime" :tableData="itemP.workOrderDtos"></whPrinterValueAddListSub>
					</div>
					
				</div>
			</div>

		</div>
		
		
		
	</div>
</template>
<script>
	import barcode from '@/components/Common/barcode.vue';
	import whPrinterValueAddListSub from '@/printer/whPrinterValueAddListSub.vue';
	export default {
		metaInfo() {
		  return {
		    title: this.title,
		  }
		},
		props: {
			isShowValueAdded: {
			  type: Boolean
			},
		},
		components: {
			barcode,
			whPrinterValueAddListSub
		},
		data() {
			return {
				title: "HYTX-PlanOutList-Bol",
				filterData: {
					ids: ''
				},
				// listUrl: [],
				loading_load: false,
				priterData: {},
				ValueAddOpenTime:""
			};
		},

		//创建时
		created() {
			if (this.$route.query && this.$route.query.ids) {
				this.filterData.ids = this.$route.query.ids.split(',');
			}
			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData(this.filterData.ids);
			},
			//请求分页数据
			getPageData(ids) {
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhTransferPlanBol, {
						ids: ids
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.priterData = data.rows;
							//标题
							if(this.priterData && this.priterData.length>0){
								this.title = this.priterData.map((item)=>{
									// if(item.print){
										return item.planNo?item.planNo:'';
									// } else {
									// 	return "",
									// }
								}).join(',');
								this.title = this.title+"-PlanOutList-Bol"
							}
							
							//增值服务
							let ValAddUpdateVal = 0;
							if(this.priterData && this.priterData.length>0){
								this.priterData.forEach(item=>{
									if(item.workOrderDtos){
										ValAddUpdateVal = ValAddUpdateVal+item.workOrderDtos.length;
									}
								});
							}
							if(ValAddUpdateVal>0){
								this.$emit('ValAddUpdate',{
									val: ValAddUpdateVal,
									show: true,
								});
							} else {
								this.$emit('ValAddUpdate',{
									val: 0,
									show: true,
								});
							}
							this.ValueAddOpenTime = new Date().getTime();
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
	// @import '@/assets/css/printer.less'
	@import url(../assets/css/printer.less);

	.page_table {
		page-break-after: always;
		margin-bottom: 10mm;
		padding: 2mm 0;
	}

	// .printerTable tr {
	// td,th {
	.prt_tit_0 {
		font-size: 6mm;
	}

	.prt_tit_1 {
		// background: #000000;
		color: #000000;
		text-align: center;
	}

	// }

	// }
	.prt_tit_2 {
		font-size: 4mm;
	}

	.prt_tit_3 {
		font-size: 3.5mm;
	}

	.prt_exp_1 {
		font-size: 2.5mm;
		line-height: 120%;
		padding: 1mm 0;
	}

	.prt_exp_2 {
		font-size: 3mm;
		line-height: 120%;
	}
</style>